import NavBar from "../Components/Navbar";
import UpdatesList from "../Components/Updates";


function Updates (){
    return (
        <>
        <NavBar /> 
        <UpdatesList/>
        </>

 )
};

export default Updates;