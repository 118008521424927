//this page will contain the details of the consonea project
import NavBar from "../Components/Navbar";
import AccordionConsonea from "../Components/AccordionConsonea";

function ProjectConsonea() {
  return (
    <>
      <NavBar />
      <AccordionConsonea />
    </>
  );
}

export default ProjectConsonea;
