import NavBar from "../Components/Navbar";
import AccordionPortfolio from "../Components/AccordionPortfolio";

function ProjectPortfolio() {
  return (
    <>
      <NavBar />
      <AccordionPortfolio />
    </>
  );
}
export default ProjectPortfolio;
