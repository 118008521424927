import NavBar from "../Components/Navbar";
import AccordionPicoResto from "../Components/AccordionPicoResto";

function ProjectPicoResto() {
  return (
    <>
      <NavBar />
      <AccordionPicoResto/>
    </>
  );
}

export default ProjectPicoResto;
