import NavBar from "../Components/Navbar";
import ContactInfo from "../Components/ContactInfo";
import "../index.css";

function Contact() {
  return (
    <>
      <NavBar />
      <ContactInfo />
    </>
  );
}

export default Contact;
