import NavBar from "../Components/Navbar";
import AccordionSpotsAndPotes from "../Components/AccordionSpotsAndPotes";

function ProjectSpotsAndPotes() {
  return (
    <>
      <NavBar />
      <AccordionSpotsAndPotes  />
      </>
      )
  }
  export default ProjectSpotsAndPotes;