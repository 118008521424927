import AccordionKindGlyphs from "../Components/AccordionKindGlyphs";
import NavBar from "../Components/Navbar";


function ProjectKindGlyphs() {
  return (
    <>
      <NavBar />
      <AccordionKindGlyphs/>
    </>
  );
}

export default ProjectKindGlyphs;
