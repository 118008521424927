//this page will contain the details of the choose your career project
import AccordionChooseYourCareer from "../Components/AccordionChooseYourCareer";
import NavBar from "../Components/Navbar";


function ProjectChooseYourCareer() {
  return (
    <>
      <NavBar />
      <AccordionChooseYourCareer/>
    </>
  );
}

export default ProjectChooseYourCareer;
